import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from "@backstage/core-plugin-api";
import { CompliancePluginBackendClient } from "./api/CompliancePluginBackendClient";
import { compliancePluginApiRef } from "./api";

import { rootRouteRef } from "./routes";

export const complianceUiPlugin = createPlugin({
  id: "compliance-ui",
  apis: [
    createApiFactory({
      api: compliancePluginApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new CompliancePluginBackendClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ComplianceUiPage = complianceUiPlugin.provide(
  createRoutableExtension({
    name: "ComplianceUiPage",
    component: () => import("./components/CompliancePage").then((m) => m.CompliancePage),
    mountPoint: rootRouteRef,
  }),
);

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from "@backstage/core-plugin-api";
import { ReadS3PluginBackendClient } from "./api/ReadS3PluginBackendClient";
import { readS3PluginApiRef } from "./api";

import { rootRouteRef } from "./routes";

export const regionalDeployPlugin = createPlugin({
  id: "regional-deploy",
  apis: [
    createApiFactory({
      api: readS3PluginApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new ReadS3PluginBackendClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const FeatureDeployPage = regionalDeployPlugin.provide(
  createRoutableExtension({
    name: "FeatureDeployPage",
    component: () =>
      import("./components/FeatureDeploymentsPage").then((m) => m.FeatureDeploymentsPage),
    mountPoint: rootRouteRef,
  }),
);

export const RegionalDeployPage = regionalDeployPlugin.provide(
  createRoutableExtension({
    name: "RegionalDeployPage",
    component: () =>
      import("./components/RegionalDeploymentsPage").then(
        (m) => m.RegionalDeploymentsPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

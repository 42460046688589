import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  githubAuthApiRef,
} from "@backstage/core-plugin-api";
import {
  ScmAuth,
  scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from "@backstage/integration-react";
import { exploreToolsConfigRef } from "@backstage-community/plugin-explore-react";
import { exploreToolsFactory } from "@internal/plugin-explore-tools";

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(exploreToolsConfigRef, new exploreToolsFactory()),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) => ScmAuth.merge(ScmAuth.forGithub(githubAuthApi)),
  }),
];

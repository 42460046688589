import {
  ExploreTool,
  ExploreToolsConfig,
} from "@backstage-community/plugin-explore-react";

const ToolSet: ExploreTool[] = [
  {
    title: "Tech Radar",
    description:
      "Tech Radar is a list of technologies, complemented by an assessment result, called ring assignment.",
    url: "/tech-radar",
    image:
      "https://storage.googleapis.com/wf-blogs-engineering-media/2018/09/fe13bb32-wf-tech-radar-hero-1024x597.png",
    tags: ["standards", "landscape"],
  },
  {
    title: "Cost Insights",
    description: "Insights into cloud costs for your organization.",
    url: "/cost-insights",
    image: "https://cloud.google.com/images/press/logo-cloud.png",
    tags: ["cloud", "finops"],
  },
  {
    title: "GraphiQL",
    description:
      "Integrates GraphiQL as a tool to browse GraphiQL endpoints inside Backstage.",
    url: "/graphiql",
    image:
      "https://camo.githubusercontent.com/517398c3fbe0687d3d4dcbe05da82970b882e75a/68747470733a2f2f64337676366c703535716a6171632e636c6f756466726f6e742e6e65742f6974656d732f33413061324e314c3346324f304c3377326e316a2f477261706869514c382e706e673f582d436c6f75644170702d56697369746f722d49643d3433363432",
    tags: ["graphql", "dev"],
  },
];

export class exploreToolsFactory implements ExploreToolsConfig {
  constructor() {}

  async getTools(): Promise<ExploreTool[]> {
    return ToolSet;
  }
}

import { ReadS3PluginApi } from "./types";
import { DiscoveryApi, IdentityApi } from "@backstage/core-plugin-api";

export class ReadS3PluginBackendClient implements ReadS3PluginApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error();
    }
    return await response.json();
  }
  async getHealth(): Promise<string> {
    const url = `${await this.discoveryApi.getBaseUrl("read-s3")}/health`;
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      method: "GET",
    });
    return await this.handleResponse(response);
  }
  async getPipelineForgeMetadata(): Promise<string> {
    const url = `${await this.discoveryApi.getBaseUrl(
      "read-s3",
    )}/pipeline-forge-metadata`;
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      method: "GET",
    });
    return await this.handleResponse(response);
  }
}

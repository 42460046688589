import { createApiRef } from "@backstage/core-plugin-api";

export interface ReadS3PluginApi {
  getHealth(): Promise<string>;
  getPipelineForgeMetadata(): Promise<string>;
}

export const readS3PluginApiRef = createApiRef<ReadS3PluginApi>({
  id: "plugin.read-s3-regional.service",
});

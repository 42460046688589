import React from "react";
import { Grid } from "@material-ui/core";
import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidingComponentsCard,
  EntityProvidedApisCard,
  EntityConsumedApisCard,
} from "@backstage/plugin-api-docs";
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityDependencyOfComponentsCard,
  EntityHasComponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  // isApiType,
  isComponentType,
  isKind,
  EntityHasResourcesCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from "@backstage/plugin-catalog";
import { Direction, EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from "@backstage/catalog-model";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import { PipelineForgePage } from "@internal/plugin-pipeline-forge";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";
import {
  EntityGithubActionsContent,
  EntityRecentGithubActionsRunsCard,
  isGithubActionsAvailable,
} from "@backstage-community/plugin-github-actions";
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
  EntityUserProfileCard,
} from "@backstage/plugin-org";
import { EntityTechdocsContent } from "@backstage/plugin-techdocs";
import {
  EntityGithubInsightsContent,
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReadmeCard,
  isGithubInsightsAvailable,
} from "@roadiehq/backstage-plugin-github-insights";
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
  EntityGithubPullRequestsOverviewCard,
} from "@roadiehq/backstage-plugin-github-pull-requests";
import { EventsCard } from "../events-card";
import {
  EntityAwsCodePipelineCard,
  EntityAwsCodePipelineExecutionsContent,
  isAwsCodePipelineAvailable,
} from "@aws/aws-codepipeline-plugin-for-backstage";
import { EntityTodoContent } from "@backstage-community/plugin-todo";

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item md={6}>
          <EntityGithubInsightsLanguagesCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item md={6}>
          <EntityGithubInsightsReadmeCard maxHeight={350} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isKind("component")}>
        <Grid item md={6} xs={12}>
          <EventsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isAwsCodePipelineAvailable}>
        <Grid item md={6}>
          <EntityAwsCodePipelineCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <Grid item md={6} xs={12}>
          <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <Grid item md={6}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependencyOfComponentsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/actions" title="Github Actions">
      <EntityGithubActionsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/codepipeline" title="CodePipeline">
      <EntityAwsCodePipelineExecutionsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/pipeline-forge" title="Deployments">
      <PipelineForgePage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="Todo">
      <EntityTodoContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/actions" title="Github Actions">
      <EntityGithubActionsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType("service")}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType("website")}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

// Create a custom eventApiPage in order to change the name of the Definition tab to "Event Schema" for added clarity. Enable once Backstage is upgraded, since isApiType is not available in the current version

// const defaultApiPage = (
//   <EntityLayout>
//     <EntityLayout.Route path="/" title="Overview">
//       <Grid container spacing={3}>
//         {entityWarningContent}
//         <Grid item md={6} xs={12}>
//           <EntityAboutCard />
//         </Grid>
//         <Grid item md={6} xs={12}>
//           <EntityCatalogGraphCard variant="gridItem" height={400} />
//         </Grid>
//         <Grid item xs={12}>
//           <Grid container>
//             <Grid item xs={12} md={6}>
//               <EntityProvidingComponentsCard />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <EntityConsumingComponentsCard />
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </EntityLayout.Route>
//
//     <EntityLayout.Route path="/definition" title="Definition">
//       <Grid container spacing={3}>
//         <Grid item xs={12}>
//           <EntityApiDefinitionCard />
//         </Grid>
//       </Grid>
//     </EntityLayout.Route>
//   </EntityLayout>
// );
//
// const eventApiPage = (
//     <EntityLayout>
//         <EntityLayout.Route path="/" title="Overview">
//             <Grid container spacing={3}>
//                 {entityWarningContent}
//                 <Grid item md={6} xs={12}>
//                     <EntityAboutCard />
//                 </Grid>
//                 <Grid item md={6} xs={12}>
//                     <EntityCatalogGraphCard variant="gridItem" height={400} />
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Grid container>
//                         <Grid item xs={12} md={6}>
//                             <EntityProvidingComponentsCard />
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                             <EntityConsumingComponentsCard />
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </EntityLayout.Route>
//
//         <EntityLayout.Route path="/definition" title="Event Schema">
//             <Grid container spacing={3}>
//                 <Grid item xs={12}>
//                     <EntityApiDefinitionCard />
//                 </Grid>
//             </Grid>
//         </EntityLayout.Route>
//     </EntityLayout>
// );
//
// const apiPage = (
//     <EntitySwitch>
//         <EntitySwitch.Case if={isApiType("eventhub")}>
//             {eventApiPage}
//         </EntitySwitch.Case>
//
//         <EntitySwitch.Case>{defaultApiPage}</EntitySwitch.Case>
//     </EntitySwitch>
// );

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependencyOfComponentsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind("component")} children={componentPage} />
    <EntitySwitch.Case if={isKind("api")} children={apiPage} />
    <EntitySwitch.Case if={isKind("group")} children={groupPage} />
    <EntitySwitch.Case if={isKind("user")} children={userPage} />
    <EntitySwitch.Case if={isKind("system")} children={systemPage} />
    <EntitySwitch.Case if={isKind("domain")} children={domainPage} />
    <EntitySwitch.Case if={isKind("resource")} children={resourcePage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

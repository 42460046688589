import React from "react";
import jfrog from "./assets/jfrog.png";
import fortify from "./assets/fortify.png";
import temppriv from "./assets/bb-temp-priv.png";
import aws from "./assets/aws.png";
import ado from "./assets/ado.png";
import miro from "./assets/miro.png";

export const Jfrog = () => {
  return <img src={jfrog} height={60} />;
};

export const Fortify = () => {
  return <img src={fortify} height={40} />;
};

export const TempPriv = () => {
  return <img src={temppriv} height={40} />;
};

export const AWS = () => {
  return <img src={aws} height={28} />;
};

export const ADO = () => {
  return <img src={ado} height={40} />;
};

export const Miro = () => {
  return <img src={miro} height={45} />;
};

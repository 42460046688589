import {
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageCompanyLogo,
} from "@backstage/plugin-home";
import { Content, Header, Page } from "@backstage/core-components";
import { HomePageSearchBar } from "@backstage/plugin-search";
import { Grid, makeStyles } from "@material-ui/core";
import { GridSpacing } from "@material-ui/core/Grid";
import React, { useState } from "react";
import { identityApiRef, useApi } from "@backstage/core-plugin-api";
import { Anthology } from "./components/AnthologyLogo";
import { Jfrog, Fortify, TempPriv, AWS, ADO, Miro } from "./components/toolkit";

const headerAttr = {
  type: "home",
  title: "Platform Backstage Portal - Home",
  subtitlePrefix: "Welcome",
};

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex",
    maxWidth: "60vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: "8px 0",
    borderRadius: "50px",
    margin: "auto",
  },
  searchBarOutline: {
    borderStyle: "none",
  },
}));

const clockConfigs: ClockConfig[] = [
  {
    label: "NYC",
    timeZone: "America/New_York",
  },
  {
    label: "London",
    timeZone: "Europe/London",
  },
  {
    label: "Prague",
    timeZone: "Europe/Prague",
  },
  {
    label: "Chennai",
    timeZone: "Asia/Kolkata",
  },
  {
    label: "Bogotá",
    timeZone: "America/Bogota",
  },
];

const toolkit = [
  { label: "AWS SSO", url: "https://d-906766da4c.awsapps.com/start#/", icon: <AWS /> },
  {
    label: "Temporary Privilege Escalation",
    url: "https://okta-priv-esc.bb-fnds.com/",
    icon: <TempPriv />,
  },
  { label: "ADO", url: "https://dev.azure.com/AnthologyInc-01/", icon: <ADO /> },
  { label: "JFrog", url: "https://blackboard.jfrog.io/ui/packages", icon: <Jfrog /> },
  { label: "Miro", url: "https://miro.com/app/dashboard/", icon: <Miro /> },
  {
    label: "Fortify on Demand",
    url: "https://ams.fortify.com/SSO/Login/96828bf5-7623-4c4c-b897-ac8e25b5aff1",
    icon: <Fortify />,
  },
];

export const HomePage = () => {
  const classes = useStyles();
  const [displayName, setDisplayName] = useState("");
  useApi(identityApiRef)
    .getProfileInfo()
    .then((newUser) => {
      setDisplayName(newUser.displayName || "");
    });
  const subtitle = `${headerAttr.subtitlePrefix}: ${displayName}`;

  return (
    <Page themeId="home">
      <Header
        title={headerAttr.title}
        type={headerAttr.type}
        subtitle={subtitle}
        pageTitleOverride="Home"
      >
        <HeaderWorldClock clockConfigs={clockConfigs} />
      </Header>

      <Content>
        <Grid container justifyContent="center">
          <HomePageCompanyLogo logo={<Anthology />} />
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={12 as GridSpacing}
          >
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <HomePageStarredEntities />
            </Grid>
            <Grid item xs={12} md={6} spacing={6}>
              <HomePageToolkit tools={toolkit} />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

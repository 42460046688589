import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import ExtensionIcon from "@material-ui/icons/Extension";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import LayersIcon from "@material-ui/icons/Layers";
import LogoFull from "./LogoFull";
import LogoIcon from "./LogoIcon";
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from "@backstage/plugin-user-settings";
import { SidebarSearchModal } from "@backstage/plugin-search";
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  Link,
} from "@backstage/core-components";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BuildIcon from "@material-ui/icons/Build";
import TocIcon from "@material-ui/icons/Toc";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarItem
        icon={HelpOutlineIcon}
        to="/docs/default/Component/getting-started-with-backstage"
        text="Introduction"
      />
      <SidebarItem icon={HomeIcon} to="/" text="Home" />
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={LayersIcon} to="explore" text="Explore">
          <SidebarSubmenu title="Explore">
            <SidebarSubmenuItem icon={MenuBookIcon} to="catalog" title="Catalog" />
            <SidebarSubmenuItem icon={ExtensionIcon} to="api-docs" title="APIs" />
            <SidebarSubmenuItem icon={LibraryBooks} to="docs" title="Docs" />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={DashboardIcon} to="bulletin-board" text="Bulletin Board" />
        {/* End global nav */}
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={TocIcon} to="read-aws-data" text="Project List" />
        <SidebarItem icon={ExtensionIcon} to="feature-deploy" text="Deployments">
          <SidebarSubmenu title="Explore">
            <SidebarSubmenuItem
              icon={ExtensionIcon}
              to="feature-deploy"
              title="Learn Feature Deployments"
            />
            <SidebarSubmenuItem
              icon={FlightTakeoffIcon}
              to="regional-deploy"
              title="Regional Deployments"
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={CheckCircleOutline} to="compliance-ui" text="Compliance" />
        <SidebarItem icon={DashboardIcon} to="devops-metrics" text="DevOps Metrics" />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
        <SidebarSettings />
        <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);

import { createPlugin, createRoutableExtension } from "@backstage/core-plugin-api";

import { rootRouteRef } from "./routes";

export const pipelineForgePlugin = createPlugin({
  id: "pipeline-forge",
  routes: {
    root: rootRouteRef,
  },
});

export const PipelineForgePage = pipelineForgePlugin.provide(
  createRoutableExtension({
    name: "PipelineForgePage",
    component: () =>
      import("./components/PipelineForgePage").then((m) => m.PipelineForgePage),
    mountPoint: rootRouteRef,
  }),
);

import { Capability, Project } from "../../../common/src/api";
import { createApiRef } from "@backstage/core-plugin-api";

export interface CompliancePluginApi {
  getHealth(): Promise<string>;
  getComplianceSummary(): Promise<string>;
  getComplianceSummaryGovcloud(): Promise<string>;
  getPipelineForgeMetadata(): Promise<string>;
}

export const compliancePluginApiRef = createApiRef<CompliancePluginApi>({
  id: "plugin.compliance-ui.service",
});

export type AccountComplianceTableProps = {
  accounts: AccountComplianceEntry[];
  partition: string;
  capabilities: Capability[];
  projects: Project[];
};

export type AccountComplianceEntry = {
  accountId: string;
  alias: string;
  regions?: RegionComplianceEntry[];
  error?: string;
};

export type RegionComplianceEntry = {
  region: string;
  compliance: string | ComplianceSummary[];
};

export type ComplianceSummary = {
  ConfigRuleName: string;
  Compliance: {
    ComplianceType: string;
    ComplianceContributorCount: ComplianceContributorCount;
  };
};

export type ComplianceContributorCount = {
  CappedCount: number;
  CapExceeded: boolean;
};

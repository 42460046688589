import { createPlugin, createRoutableExtension } from "@backstage/core-plugin-api";

import { rootRouteRef } from "./routes";

export const devopsMetricsPlugin = createPlugin({
  id: "devops-metrics",
  routes: {
    root: rootRouteRef,
  },
});

export const DevopsMetricsPage = devopsMetricsPlugin.provide(
  createRoutableExtension({
    name: "DevopsMetricsPage",
    component: () =>
      import("./components/DashboardComponent").then((m) => m.DashboardComponent),
    mountPoint: rootRouteRef,
  }),
);

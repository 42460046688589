import { createApp } from "@backstage/app-defaults";
import { AppRouter, FlatRoutes } from "@backstage/core-app-api";
import { githubAuthApiRef } from "@backstage/core-plugin-api";
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from "@backstage/core-components";
import { apiDocsPlugin, ApiExplorerPage } from "@backstage/plugin-api-docs";
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from "@backstage/plugin-catalog";
import { CatalogImportPage, catalogImportPlugin } from "@backstage/plugin-catalog-import";
import { UserListFilterKind } from "@backstage/plugin-catalog-react";
import {
  ScaffolderPage,
  scaffolderPlugin,
  ScaffolderFieldExtensions,
} from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { SearchPage } from "@backstage/plugin-search";
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from "@backstage/plugin-techdocs";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";
import { RequirePermission } from "@backstage/plugin-permission-react";
import { catalogEntityCreatePermission } from "@backstage/plugin-catalog-common/alpha";
import { UserSettingsPage } from "@backstage/plugin-user-settings";
import React from "react";
import { Route } from "react-router-dom";

import { apis } from "./apis";
import { Root } from "./components/Root";
import { entityPage } from "./components/catalog/EntityPage";

import { searchPage } from "./components/search/SearchPage";
import { LowerCaseValuePickerFieldExtension } from "./components/scaffolder/customScaffolderExtensions";
import * as plugins from "./plugins";
import { oktaAuthApiRef } from "@backstage/core-plugin-api";
import { HomepageCompositionRoot } from "@backstage/plugin-home";
import { HomePage } from "./components/home/HomePage";
import { ExplorePage, explorePlugin } from "@backstage-community/plugin-explore";
import { Mermaid } from "backstage-plugin-techdocs-addon-mermaid";
import type { IdentityApi } from "@backstage/core-plugin-api";
import { discoveryApiRef, useApi } from "@backstage/core-plugin-api";
import { setTokenCookie } from "./cookieAuth";
import { ReadAwsDataPage } from "plugin-read-aws-data";
import { ComplianceUiPage } from "@internal/plugin-compliance-ui";
import { DevopsMetricsPage } from "@internal/plugin-devops-metrics";
import { PipelineForgePage } from "@internal/plugin-pipeline-forge";
import { DevToolsPage } from "@backstage/plugin-devtools";
import { customDevToolsPage } from "./components/devtools/CustomDevToolsPage";
import { CatalogUnprocessedEntitiesPage } from "@backstage/plugin-catalog-unprocessed-entities";
import { BulletinBoardPage } from "backstage-plugin-bulletin-board";
import { FeatureDeployPage, RegionalDeployPage } from "@internal/plugin-regional-deploy";

const providerOkta: SignInProviderConfig = {
  id: "okta-auth-provider",
  title: "Okta",
  message: "If you don't have a GitHub account, sign in using Okta",
  apiRef: oktaAuthApiRef,
};

const providerGitHub: SignInProviderConfig = {
  id: "github-auth-provider",
  title: "Github",
  message: "Sign in using GitHub",
  apiRef: githubAuthApiRef,
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: (props) => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          providers={[providerGitHub, providerOkta]}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(await discoveryApi.getBaseUrl("cookie"), identityApi);

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
  },
});

const filterAll: UserListFilterKind = "all";

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter={filterAll} />}
    />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <Mermaid config={{ theme: "forest", themeVariables: { lineColor: "#000000" } }} />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <LowerCaseValuePickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/read-aws-data" element={<ReadAwsDataPage />} />
    <Route path="/compliance-ui" element={<ComplianceUiPage />} />
    <Route path="/devops-metrics" element={<DevopsMetricsPage />} />
    <Route path="/pipeline-forge" element={<PipelineForgePage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/bulletin-board" element={<BulletinBoardPage />} />
    <Route path="/feature-deploy" element={<FeatureDeployPage />} />
    <Route path="/regional-deploy" element={<RegionalDeployPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);

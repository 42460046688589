import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { useEntity, catalogApiRef, EntityRefLink } from "@backstage/plugin-catalog-react";
import { Entity } from "@backstage/catalog-model";
import { useApi } from "@backstage/core-plugin-api";

export function EventsCard() {
  const { entity } = useEntity();
  const entityName = entity.metadata.name;
  const prefix = "component:default/";
  const componentTargetRef = prefix + entityName;

  const [events, setEvents] = useState<Entity[] | null>(null);

  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const findEvents = async () => {
      try {
        const response = await catalogApi.getEntities({
          filter: {
            kind: "api",
            "spec.type": "eventhub",
            "relations.apiProvidedBy": componentTargetRef,
          },
        });
        return response.items;
      } catch (error) {
        console.error("Error finding events:", error);
        return null;
      }
    };

    const searchEvents = async () => {
      const foundEvents = await findEvents();
      setEvents(foundEvents);
    };

    if (!events) {
      searchEvents().catch((error) => {
        console.error("Error searching for events:", error);
      });
    }
  }, []); // Empty dependency array to run the effect only on mount

  // All events have a dependency on schema-registry, so in that specific case, we're limiting the list of events to ones that start with 'bb.event.schema.registry'
  const eventsArray =
    entityName === "fcc-evt-schema-registry"
      ? events?.filter((event) =>
          event.metadata.name.startsWith("bb.event.schema.registry"),
        )
      : events;

  return (
    <div>
      <Card>
        <CardHeader title="EventHub Events" />
        <Divider style={{ marginTop: "16px" }} />
        <div>
          {eventsArray && eventsArray.length > 0 ? (
            eventsArray.map((event) => (
              <div key={event.metadata.name}>
                <CardContent>
                  <EntityRefLink
                    entityRef={{
                      kind: "api",
                      namespace: "default",
                      name: event.metadata.name,
                    }}
                  >
                    {event.metadata.name}
                  </EntityRefLink>
                </CardContent>
              </div>
            ))
          ) : (
            <p>&nbsp;&nbsp;&nbsp;&nbsp;This service doesn't publish any events.</p>
          )}
        </div>
      </Card>
    </div>
  );
}

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from "@backstage/core-plugin-api";
import { ReadS3PluginBackendClient } from "./api/ReadS3PluginBackendClient";
import { readS3PluginApiRef } from "./api";

import { rootRouteRef } from "./routes";

export const readAwsDataPlugin = createPlugin({
  id: "read-s3",
  apis: [
    createApiFactory({
      api: readS3PluginApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new ReadS3PluginBackendClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ReadAwsDataPage = readAwsDataPlugin.provide(
  createRoutableExtension({
    name: "ReadAwsDataPage",
    component: () =>
      import("./components/ProjectListPage").then((m) => m.ProjectListPage),
    mountPoint: rootRouteRef,
  }),
);

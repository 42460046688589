import { createApiRef } from "@backstage/core-plugin-api";
import { Account, Capability, Project } from "../../../common/src/api";

export interface ReadS3PluginApi {
  getHealth(): Promise<string>;
  getPipelineForgeMetadata(): Promise<string>;
}

export const readS3PluginApiRef = createApiRef<ReadS3PluginApi>({
  id: "plugin.read-s3.service",
});

export type AccountTableProps = {
  accounts: Account[];
};

export type CapabilityTableProps = {
  capabilities: Capability[];
};

export type ProjectTableProps = {
  projects: Project[];
  capabilities: Capability[];
};
